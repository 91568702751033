@font-face {
  font-family: "roboto-regular";
  src: url("/fonts/Roboto-Regular.ttf");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}
@font-face {
  font-family: "roboto-bold";
  src: url("/fonts/Roboto-Medium.ttf");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "roboto-black";
  src: url("/fonts/Roboto-Black.ttf");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "moche-bold";
  src: url("/fonts/Moche-Bold.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "moche-light";
  src: url("/fonts/Moche-Light.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "moche-regular";
  src: url("/fonts/Moche-Regular.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "lemurmure-regular";
  src: url("/fonts/LeMurmure-Regular.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "helmet-regular";
  src: url("/fonts/Helmet-Regular.ttf");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "monument-ultra";
  src: url("/fonts/MonumentExtended-Ultrabold.otf");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "hubot-regular";
  src: url("/fonts/Hubot-Sans-Regular.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "hubot-extrabold";
  src: url("/fonts/Hubot-Sans-ExtraBold.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "nightwatch-regular";
  src: url("/fonts/TheNightWatch.ttf");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "misto-regular";
  src: url("/fonts/Misto.woff2");
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

